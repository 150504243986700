import tailwind from 'tailwind-rn'
import classNames from 'classnames'
import {
  ld,
  THEME_KEY,
  ios13C,
  iosC,
  getThemeObjectFromThemesObject,
} from '~/submodules/shared-react-native/components/AppTheme'
import {
  cHeyeddSoftwares,
  cBlue,
  cOrangeChinese,
  cSpicyKorean,
} from './theme.theme.colors'

const c = cHeyeddSoftwares

export const themes = {
  // Theme colors
  primary: ld(c.primary, c.primary),
  secondary: ld(c.secondary, c.secondary),
  tertiary: ld(c.tertiary, c.tertiary),
  // Light/Dark modes
  // text: ld('#000000', '#ffffff'),
  text: ld('#000000', '#E5E7EB'),
  background: ld('#f2f3f5', '#000000'), // not completely white (little bit black)
  backgroundEven: ld('#FFF6ED', iosC.grey), // [THEME_MODE_KEY.dark]: '#86827D',
  // Reverse Light/Dark modes
  textReverse: ld('#ffffff', '#000000'),
  backgroundReverse: ld('#000000', '#f2f3f5'),
  // Extra colors
  ios13C,
  iosC,
  extras: c.extras || {},
  // Component colors
  components: {
    layout: {
      // background: ios13C.systemGreenColor,
      background: ios13C.systemBlueColor,
      // background: ios13C.systemBlueColor,
      // background: ios13C.systemBlueColor,
      // background: ios13C.systemBlueColor,
      // background: ios13C.systemYellowColor,
      // background: ld(
      //   ios13C.systemYellowColor.light,
      //   ios13C.systemBlueColor.light,
      // ),
    },
    // // These are for the navigator - refactor to put into a 'tab' object. (a bit of nesting to group together)
    // tabIconDefault: '#ccc',
    // tabIconSelected: c.primary,
    // rows: ld(
    //   {
    //     even: {},
    //     odd: {
    //       background: 'bg-gray-100',
    //     },
    //   },
    //   {
    //     even: {},
    //     odd: {
    //       background: 'bg-gray-100 bg-opacity-20',
    //     },
    //   },
    // ),
    // divider: {
    //   color: iosC.grey,
    //   heightTw: 'h-0.5',
    // },
    // modal: {
    //   buttonClose: {
    //     backgroundColor: c.primary,
    //   },
    // },
    // textInput: {
    //   backgroundColorRight: iosC.green,
    //   backgroundColorWrong: iosC.red,
    // },
    // options: {
    //   backgroundColor: iosC.grey,
    // },
    // logo: {
    //   textColor: c.tertiary,
    // },
    // switch: {
    //   offColor: iosC.grey,
    //   track: {
    //     off: '#767577',
    //     on: c.tertiary,
    //   },
    //   thumb: {
    //     off: '#3e3e3e',
    //     on: c.primary,
    //   },
    //   iosBackgroundColor: '#3e3e3e',
    // },
  },
}
