import { createContext, useContext, FC, ReactNode } from 'react'

const defaultValue = {}
const AppContext = createContext(defaultValue)

export const AppContextProvider: FC<{
  contextValue: object
  children: ReactNode
}> = ({ contextValue, children }) => {
  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  )
}

export const useAppContext = () => {
  return useContext(AppContext) as { theme: any; lang: any, setModalVisible: any, settings: any, isWebView: boolean }
}
