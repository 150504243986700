import React, { useState, useRef, useEffect } from 'react'
import tailwind from 'tailwind-rn'
import classNames from 'classnames'
import Link from 'next/link'
import { View, Text } from 'react-native'
import Dropdown from '../utils/Dropdown'
import Transition from '../utils/Transition'
import DarkModeSwitch from './Header.DarkModeSwitch'
import Logo from './Logo'
import { useAuth } from '~/lib/user/useAuth'
import { useAppContext } from '~/submodules/shared-react-native/components/AppContext'

const LINKS = [
  { label: 'Home', link: '/' },
  { label: 'Posts', link: '/posts' },
  { label: 'App', link: '/app' },
  // { label: 'Portfolio', link: '/portforlio' },
  // {label: 'Portfolio', link: '/resume'}
  // {label: 'Portfolio', link: '/contact_me'}
]

const Nav = ({ links = LINKS }: any) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const trigger = useRef(null)
  const mobileNav = useRef(null)
  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return
      if (
        !mobileNavOpen ||
        mobileNav.current.contains(target) ||
        trigger.current.contains(target)
      )
        return
      setMobileNavOpen(false)
    }
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })
  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return
      setMobileNavOpen(false)
    }
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  })
  const { user } = useAuth()
  const { theme = {}, isDark } = useAppContext()

  return (
    <>
      {/* Desktop navigation */}
      <nav className="hidden md:flex md:grow">
        {/* Desktop menu links */}
        <ul className="flex grow flex-wrap items-center font-medium">
          {links.map(({ label, link }) => {
            return (
              <li key={`${label}${link}`}>
                <Link href={link}>
                  <span
                    className={classNames(
                      'transition duration-150 ease-in-out',
                      'px-5 py-2 flex items-center',
                      { 'text-gray-600 hover:text-gray-900': !isDark },
                      { 'text-gray-300 hover:text-gray-100': isDark },
                    )}
                  >
                    {label}
                  </span>
                </Link>
              </li>
            )
          })}

          {/* <!-- 1st level: hover -->
      <Dropdown title="Resources">
         <!-- 2nd level: hover --> 
        <li>
          <Link
            to="/help"
            className="text-sm text-gray-600 dark:text-gray-400 hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight"
          >
            Help center
          </Link>
        </li>
        <li>
          <Link
            to="/404"
            className="text-sm text-gray-600 dark:text-gray-400 hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight"
          >
            404
          </Link>
        </li>
      </Dropdown> */}
        </ul>

        {/* Desktop lights switch */}
        <DarkModeSwitch.desktop />

        {/* Desktop CTA on the right */}
        {/* <ul className="flex justify-end flex-wrap items-center">
      <li>
        <Link
          to="/contact"
          className="btn-sm text-white bg-teal-500 hover:bg-teal-400 ml-6"
        >
          Request code
        </Link>
      </li>
    </ul> */}
      </nav>

      {/* Mobile menu */}
      <div className="inline-flex md:hidden">
        {/* Mobile lights switch */}
        <DarkModeSwitch.mobile />

        {/* Hamburger button */}
        <button
          ref={trigger}
          className={`hamburger ${mobileNavOpen && 'active'}`}
          aria-controls="mobile-nav"
          aria-expanded={mobileNavOpen}
          onClick={() => setMobileNavOpen(!mobileNavOpen)}
        >
          <span className="sr-only">Menu</span>
          <svg
            className="w-6 h-6 fill-current text-gray-900 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 transition duration-150 ease-in-out"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="4" width="24" height="2" rx="1" />
            <rect y="11" width="24" height="2" rx="1" />
            <rect y="18" width="24" height="2" rx="1" />
          </svg>
        </button>

        {/*Mobile navigation */}
        <Transition
          show={mobileNavOpen}
          tag="ul"
          className={classNames(
            'fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll shadow-lg',
            { 'bg-white': !isDark },
            { 'bg-gray-900': isDark },
          )}
          enter="transition ease-out duration-200 transform"
          enterStart="opacity-0 -translate-x-full"
          enterEnd="opacity-100 translate-x-0"
          leave="transition ease-out duration-200"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
        >
          <nav
            id="mobile-nav"
            ref={mobileNav}
            className={classNames(
              'fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll shadow-lg no-scrollbar',
              { 'bg-white': !isDark },
              { 'bg-gray-900': isDark },
            )}
          >
            <div className="py-6 pr-4 pl-20">
              {/* Logo */}
              <Logo />
              {/* Links */}
              <ul>
                {[
                  ...links,
                  !user
                    ? { label: 'Sign in', link: '/user/signin' }
                    : { label: 'Sign out', link: '/user/signout' },
                ].map(({ label, link }) => {
                  return (
                    <li key={`${label}${link}`}>
                      <Link
                        href={link}
                        className={classNames(
                          'flex py-2',
                          { 'text-gray-600 hover:text-gray-900': !isDark },
                          { 'text-gray-300 hover:text-gray-100': isDark },
                        )}
                      >
                        {label}
                      </Link>
                    </li>
                  )
                })}

                {/* <li className="py-2 my-2 border-t border-b border-gray-200 dark:border-gray-800">
                  <span className="flex text-gray-600 dark:text-gray-400 py-2">
                    Resources
                  </span>
                  <ul className="pl-4">
                    <li>
                      <Link
                        to="/help"
                        className="text-sm flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2"
                      >
                        Help center
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/404"
                        className="text-sm flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2"
                      >
                        404
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className="font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded text-white bg-teal-500 hover:bg-teal-400 transition duration-150 ease-in-out"
                  >
                    Request code
                  </Link>
                </li> */}
              </ul>
            </div>
          </nav>
        </Transition>
      </div>
    </>
  )
}

export default Nav
