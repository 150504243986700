export { Storage } from './Storage' // todo: This should be removed. Maybe moved to AppContext or AppSettings? It does not relate to AppTheme!
export { default as Layout } from './Layout'
export { LANGUAGE_KEY } from './languages'

export const THEME_KEY = {
  light: 'light',
  dark: 'dark',
}

// Generate a LightDarkObject
export const ld = (lightValue: any, darkValue: any) => ({
  [THEME_KEY.light]: lightValue,
  [THEME_KEY.dark]: darkValue,
})
