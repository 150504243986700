export const Storage = {
  keys: {
    // the params of theme are in settings
    settings: 'settings',
    history: 'history',
    appData: 'appData',
    user: 'user',
    userData: 'userData',
  },
}
