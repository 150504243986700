import useColorScheme from './useColorScheme'
import { THEME_KEY } from './constants'

export const getThemeObjectFromThemesObject = ({
  themes,
  themeName = 'OrangeChineseApp',
  theme = {}, // This is not really a prop. It is just there for recursion.
}: any): any => {
  if (themes[themeName]) {
    return themes[themeName]
  }
  Object.keys(themes).forEach(item1 => {
    theme[item1] = {}
    if (typeof themes[item1] !== 'object') {
      theme[item1] = themes[item1]
    } else if (themes[item1][themeName] !== undefined) {
      theme[item1] = themes[item1][themeName]
    } else {
      getThemeObjectFromThemesObject({
        themes: themes[item1],
        themeName,
        theme: theme[item1],
      })
    }
  })
  return theme
}

/**
 * This has the same interface as just simply:
 * const [isDark, setIsDark] = useState(true)
 *
 * However, it adds value by:
 * - Adding persistence to the users isDark preference.
 *   It is passed the "settingsAsyncStorage" object where
 *   we can retrieve/save the user's preference for isDark.
 * - If the user's saved preference is unavailable, then try to decide
 *   isDark by using the device's preferred color.
 */
export const useAppIsDark = (settingsAsyncStorage: any) => {
  const DEFAULT_DARK_MODE = THEME_KEY.light
  // const settingsAsyncStorage = useStorage({ key: Storage.keys.settings });
  // 1. get dark value (boolean) from localStorage
  let isDark = settingsAsyncStorage.value()?.isDark
  const setIsDark = (newIsDark: boolean) => {
    // If there is no values in localStorage, it generates with empty value within the Object.
    // todo: possible solution --get rid of the useState in it, just read from localStorage.
    const currentSettings = settingsAsyncStorage.value()
    console.log('Isdark setting value: ====> ', JSON.stringify(currentSettings))
    const newSettings = {
      ...currentSettings,
      isDark: newIsDark,
    }
    settingsAsyncStorage.save(newSettings)
  }
  // else, look up color from the device
  const devicePreferredColor = useColorScheme()
  if (isDark === undefined) {
    isDark = devicePreferredColor === 'dark' ? true : false
  }
  // else, use default dark mode color
  if (isDark === undefined) {
    isDark = DEFAULT_DARK_MODE
  }
  return { isDark, setIsDark }
}
