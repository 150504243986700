import { ld } from '~/submodules/shared-react-native/components/AppTheme'

export const cHeyeddSoftwares = {
  primary: '#042FEE',
  secondary: '#0037ED',
  tertiary: '#B2C4FA',
  extras: {
    x: '#7394FF',
    y: '#CCCCCC', // - menu items
  },
}

// - Blue color scheme
export const cBlue = {
  primary: '#2f95dc',
  secondary: '#00308F',
  tertiary: '#B9D9EB',
}

// - Orange color scheme
const orangeChinesePrimary = '#FF7E00'
export const cOrangeChinese = {
  primary: ld(orangeChinesePrimary, orangeChinesePrimary),
  secondary: '#FF5733',
  tertiary: '#FFC300',
  extras: {
    // tertiary: '#FFE4B5',
  },
}

// - SpicyKorean color scheme
export const cSpicyKorean = {
  primary: ld('#E00522', '#E00522'),
  secondary: ld('#F37283', '#BA041C'),
  tertiary: ld('#89A754', '#657B3E'),
  extras: {
    // F37283 - lighter red
    // E00522 - main (red)
    // BA041C - darker red
    // 89A754 - green
    // 657B3E - darker green
  },
}
