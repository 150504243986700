/**
 * In Next.js, this is the only file where css files can be imported/required.
 * Hence the global.scss which contains the tailwind imports
 * must be imported from here.
 *
 */
require('~/layout/global.scss')
// import '@fortawesome/fontawesome-svg-core/styles.css' // import Font Awesome CSS

import { useEffect, useState, Fragment } from 'react'
import tailwind from 'tailwind-rn'
import classNames from 'classnames'
import TagManager from 'react-gtm-module'
import { useRouter } from 'next/router'
import HtmlHead from 'next/head'
import Layout from '~/layout'
import Head from '~/layout/partials/Head'
import { AppContextProvider } from '~/submodules/shared-react-native/components/AppContext'
import { View, Text } from 'react-native'
import {
  THEME_KEY,
  getThemeObjectFromThemesObject,
} from '~/submodules/shared-react-native/components/AppTheme'
import { AuthProvider, AuthGate } from '~/lib/user/useAuth'
import { useLocalStorage } from '~/submodules/shared-react-native/components/Storage/utils/useLocalStorage'
import { gtmId } from '~/constants'
import { themes } from '../components.website/theme/theme.theme'

export default function App({ Component, pageProps }) {
  console.log(`Build_Id: ${process.env.CONFIG_BUILD_ID}`)
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      TagManager.initialize({ gtmId })
    } else {
      console.log('NON PROD - TagManager will not initialize. gtmId', gtmId)
    }
  }, [])

  const LayoutComponent =
    Component.layout || Layout || (children => <>{children}</>)

  // const [isDark, setIsDark] = useState(true)
  const { value: settings, setValue: setSettings } = useLocalStorage({
    key: 'settings',
    defaultValue: { isDark: true },
  })
  const { isDark } = settings
  const themeName = isDark ? THEME_KEY.dark : THEME_KEY.light
  const theme = getThemeObjectFromThemesObject({
    themes,
    themeName,
  })

  // Theme
  const contextValue = {
    // theme: {
    //   backgroundColorTw: 'bg-red-500',
    //   backgroundColor: '#ffff00',
    // },
    theme: {
      ...theme,
      backgroundColor: theme.primary,
      // backgroundColor: theme.secondary,
      // backgroundColor: theme.tertiary,
    },
    // isDark,
    // setIsDark,
    isDark,
    setIsDark: newIsDark => {
      setSettings({ ...settings, isDark: newIsDark })
    },
  }

  const router = useRouter()
  const currentPath = router.asPath
  const securePaths = ['/ability_portal']

  return (
    <>
      <HtmlHead>
        <link rel="shortcut icon" href="/images/favicon.ico" />
      </HtmlHead>
      <AuthProvider>
        <AuthGate securePaths={securePaths} currentPath={currentPath}>
          <AppContextProvider contextValue={contextValue}>
            <LayoutComponent>
              <Head />
              <Component {...pageProps} />
            </LayoutComponent>
          </AppContextProvider>
        </AuthGate>
      </AuthProvider>
    </>
  )
}
