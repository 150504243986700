import { useState, useEffect } from 'react'

// todo: This hook has a problem where it can not be used in multiple places
//   to access the same key. Because the state is local to each instance of the
//   useLocalStorage.
//   It would be so nice if this can be resolved. How can we resolve this?
// Hook
// Copied from here: https://usehooks.com/useLocalStorage/
export const useLocalStorage = ({
  key,
  defaultValue = {},
}: {
  key: string
  defaultValue?: any
}) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(defaultValue)

  useEffect(() => {
    async function runAsync() {
      if (typeof window === 'undefined') {
        return
      }
      try {
        // Get from local storage by key
        const item = window.localStorage.getItem(key)
        // Parse stored json or if none return defaultValues
        if (item) {
          const itemResult = item ? JSON.parse(item) : defaultValue
          setStoredValue(itemResult)
        }
      } catch (error) {
        console.error(
          `LocalStorage error has occurred for [key: ${key}]`,
          error,
        )
      }
    }
    runAsync()
  }, [])

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: object) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }
  return { value: storedValue, setValue }
}
