import React, { useEffect } from 'react'
import classNames from 'classnames'
import AOS from 'aos'
import { useRouter } from 'next/router'
import Header from './partials/Header'
import Footer from './partials/Footer'
import PageIllustration from './partials/PageIllustration'
import { View, Text } from 'react-native'
import { useAppContext } from '~/submodules/shared-react-native/components/AppContext'

const LayoutComponent = ({ children }) => {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 750,
      easing: 'ease-out-quart',
    })
  })

  const router = useRouter()
  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
  }, [router.asPath]) // triggered on route change

  const { theme = {}, isDark } = useAppContext()
  return (
    <div
      className={classNames(
        'flex flex-col min-h-screen overflow-hidden',
        { 'bg-white text-gray-900': !isDark },
        { 'bg-gray-900 text-gray-100': isDark },
      )}
    >
      <Header />
      <main className={classNames('grow')}>
        <div className={classNames('relative max-w-6xl mx-auto')}>
          <div
            // className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1"
            aria-hidden="true"
          >
            <PageIllustration />
          </div>
          <div
            className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1"
            aria-hidden="true"
          >
            <PageIllustration />
          </div>

          <div
            className={classNames(
              'relative',
              'pt-24 md:pt-32',
              // 'pb-10 md:pb-16',
              // 'pt-32 md:pt-40',
            )}
          >
            <div className={classNames('h-full', 'p-[28px]')}>{children}</div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  )
}
export default LayoutComponent
