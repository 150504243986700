// Copied from: https://usehooks.com/useAuth/
// Just removed the firebase code.
import React, { useState, useEffect, useContext, createContext } from 'react'
import _ from 'lodash'
import { useRouter } from 'next/router'
import jwt_decode from 'jwt-decode'
import { useLocalStorage } from '~/submodules/shared-react-native/components/Storage/utils/useLocalStorage'

const AuthContext = createContext({})
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function AuthProvider({ children }) {
  const authContextValue = useAuthContextValue()
  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  )
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(AuthContext)
}

export function AuthGate({ securePaths, currentPath, children }) {
  const auth = useAuth() as any
  const router = useRouter()

  if (process.browser) {
    const isSecurePath =
      _.findIndex(securePaths, path => {
        return currentPath.startsWith(path)
      }) !== -1

    if (currentPath === '/user/signin') {
      return <>{children}</>
    }
    if (
      !isSecurePath
      // && !auth.user // uncomment if testing..
    ) {
      return <>{children}</>
    }

    // check the jwt exp
    const decoded = jwt_decode(auth.user.sessionToken) as any
    const secondsSinceEpoch = Math.round(Date.now() / 1000)
    // console.log('DEBUG decoded', decoded)
    // console.log('DEBUG decoded.exp', {
    //   exp: decoded.exp,
    //   date: new Date(decoded.exp * 1000),
    // })
    // console.log('DEBUG secondsSinceEpoch', {
    //   secondsSinceEpoch,
    //   date: new Date(secondsSinceEpoch * 1000),
    // })

    // check if user exists
    if (!auth.user) {
      // todo: I should pass some information that this is a redirect. So that the user can return back to this page after signin. Also a notice, that you must be logged in to view this page is shown.
      console.log('Auth: Redirect to signin - no user')
      router.push('/user/signin')
    }
    // check if it is expired
    if (secondsSinceEpoch > decoded.exp) {
      // todo: I should pass some information that this is a redirect. So that the user can return back to this page after signin. Also a notice, that you must be logged in to view this page is shown.
      console.log('Auth: Redirect to signin - token has expired')
      router.push('/user/signin')
    }
  }

  return <>{children}</>
}

// Provider hook that creates auth object and handles state
const useAuthContextValue = () => {
  // const [user, setUser] = useState(null)
  const { value: user, setValue: setUser } = useLocalStorage({
    key: 'user',
    defaultValue: null,
  })

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = ({ user }) => {
    setUser(user)
  }
  const signout = () => {
    setUser(null)
  }
  const signup = (email, password) => {
    // return firebase
    //   .auth()
    //   .createUserWithEmailAndPassword(email, password)
    //   .then(response => {
    //     setUser(response.user)
    //     return response.user
    //   })
  }
  const sendPasswordResetEmail = email => {
    // return firebase
    //   .auth()
    //   .sendPasswordResetEmail(email)
    //   .then(() => {
    //     return true
    //   })
  }
  const confirmPasswordReset = (code, password) => {
    // return firebase
    //   .auth()
    //   .confirmPasswordReset(code, password)
    //   .then(() => {
    //     return true
    //   })
  }
  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    // const unsubscribe = firebase.auth().onAuthStateChanged(user => {
    //   if (user) {
    //     setUser(user)
    //   } else {
    //     setUser(false)
    //   }
    // })
    // // Cleanup subscription on unmount
    // return () => unsubscribe()
  }, [])
  // Return the user object and auth methods
  return {
    user,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
  }
}
