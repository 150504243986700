import { ld } from './constants'

// source: https://www.xueui.cn/design-theory/dark-dark-adaptation.html
export const iosC = {
  // The colors are copy from ios 13: ld(lightColor, darkColor)
  lightblue: ld('rgb(90, 200, 250)', 'rgb(100, 210, 255)'),
  blue: ld('rgb(0, 122, 255)', 'rgb(10, 132, 255)'),
  deepblue: ld('rgb(88, 86, 214)', 'rgb(94, 92, 230)'),
  grey: ld('rgb(142, 142, 147)', 'rgb(152, 152, 157)'),
  green: ld('rgb(52, 199, 89)', 'rgb(48, 209, 88)'),
  purple: ld('rgb(175, 82, 222)', 'rgb(191, 90, 242)'),
  coral: ld('rgb(255, 45, 85)', 'rgb(255, 55, 95)'),
  red: ld('rgb(255, 59, 48)', 'rgb(255, 69, 58)'),
  orange: ld('rgb(255, 149, 0)', 'rgb(255, 159, 0)'),
  yellow: ld('rgb(255, 204, 0)', 'rgb(255, 214, 0)'),
  // ios 13 colors end here
}

// source url: https://sarunw.com/posts/dark-color-cheat-sheet/#cheat-sheet
export const ios13C = {
  // All ios13 color: ld(lightColor, darkColor)
  labelColor: ld('rgba(0, 0, 0, 1.00)', 'rgba(255, 255, 255, 1.00)'),
  secondaryLabelColor: ld(
    'rgba(60, 60, 67, 0.60)',
    'rgba(235, 235, 245, 0.60)',
  ),
  tertiaryLabelColor: ld('rgba(60, 60, 67, 0.30)', 'rgba(235, 235, 245, 0.30)'),
  quaternaryLabelColor: ld('rgba(60, 60, 67, 0.18)', '235, 235, 245, 0.18'),
  linkColor: ld('rgba(0, 122, 255, 1.00)', 'rgba(9, 132, 255, 1.00)'),
  placeholderTextColor: ld(
    'rgba(60, 60, 67, 0.30)',
    'rgba(235, 235, 245, 0.30)',
  ),
  separatorColor: ld('rgba(60, 60, 67, 0.29)', 'rgba(84, 84, 88, 0.60)'),
  opaqueSeparatorColor: ld(
    'rgba(198, 198, 200, 1.00)',
    'rgba(56, 56, 58, 1.00)',
  ),
  systemBackgroundColor: ld('rgba(255, 255, 255, 1.00)', 'rgba(0, 0, 0, 1.00)'),
  secondarySystemBackgroundColor: ld(
    'rgba(242, 242, 247, 1.00)',
    'rgba(28, 28, 30, 1.00)',
  ),
  tertiarySystemBackgroundColor: ld(
    'rgba(255, 255, 255, 1.00)',
    'rgba(44, 44, 46, 1.00)',
  ),
  systemGroupedBackgroundColor: ld(
    'rgba(242,242,247,1.00)',
    'rgba(0, 0, 0, 1.00)',
  ),
  secondarySystemGroupedBackgroundColor: ld(
    'rgba(255,255,255,1.00)',
    'rgba(28, 28, 30, 1.00)',
  ),
  tertiarySystemGroupedBackgroundColor: ld(
    'rgba(242,242,247,1.00)',
    'rgba(44, 44, 46, 1.00)',
  ),
  systemFillColor: ld('rgba(120, 120, 128, 0.20)', 'rgba(120, 120, 128, 0.36)'),
  secondarySystemFillColor: ld(
    'rgba(120, 120, 128, 0.16)',
    'rgba(120, 120, 128, 0.32)',
  ),
  tertiarySystemFillColor: ld(
    'rgba(118, 118, 128, 0.12)',
    'rgba(118, 118, 128, 0.24)',
  ),
  quaternarySystemFillColor: ld(
    'rgba(116, 116, 128, 0.08)',
    'rgba(118, 118, 128, 0.18)',
  ),
  systemRedColor: ld('rgba(255, 59, 48, 1.00)', 'rgba(255, 69, 58, 1.00)'),
  systemGreenColor: ld('rgba(52, 199, 89, 1.00)', 'rgba(48, 209, 88, 1.00)'),
  systemBlueColor: ld('rgba(0, 122, 255, 1.00)', 'rgba(10, 132, 255, 1.00)'),
  systemOrangeColor: ld('rgba(255, 149, 0, 1.00)', 'rgba(255, 159, 10, 1.00)'),
  systemYellowColor: ld('rgba(255, 204, 0, 1.00)', 'rgba(255, 214, 10, 1.00)'),
  systemPinkColor: ld('rgba(255, 45, 85, 1.00)', 'rgba(255, 55, 95, 1.00)'),
  systemPurpleColor: ld('rgba(175, 82, 222, 1.00)', 'rgba(191, 90, 242, 1.00)'),
  systemTealColor: ld('rgba(90, 200, 250, 1.00)', 'rgba(100, 210, 255, 1.00)'),
  systemIndigoColor: ld('rgba(88, 86, 214, 1.00)', 'rgba(94, 92, 230, 1.00)'),
  systemGrayColor: ld('rgba(142, 142, 147, 1.00)', 'rgba(142, 142, 147, 1.00)'),
  systemGray2Color: ld('rgba(174, 174, 178, 1.00)', 'rgba(99, 99, 102, 1.00)'),
  systemGray3Color: ld('rgba(199, 199, 204, 1.00)', 'rgba(72, 72, 74, 1.00)'),
}
