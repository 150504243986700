import React from 'react'
import Head from 'next/head'
import { siteTitle } from '~/constants'

const HeadComponent = ({ document = {} }: any) => {
  // Page title.
  // Lets keep title to 60 chars max. (youtube 66, google 60). So keep page title to 45 and append website name 15.
  const title = document.title ? `${document.title} | ${siteTitle}` : siteTitle

  return (
    <Head>
      <title>{title}</title>
    </Head>
  )
}
export default HeadComponent
